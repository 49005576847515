import React from "react"
import { Link, Text } from "@theme-ui/components"

// This way you are able to use the components directly in your MDX file without importing them

const components = {
  Text: ({ children, ...props }) => (
    <Text {...props}>
      <Link sx={{ color: "primary" }} href="#!">
        {children}
      </Link>
    </Text>
  ),
}

export default components
