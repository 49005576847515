// https://tailwindcss.com/docs/customizing-colors

import { baseColors } from "./tailwind"

export default {
  ...baseColors,
  text: baseColors.gray[8],
  background: baseColors.orange[1],
  primary: baseColors.orange[6],
  primaryHover: baseColors.orange[7],
  secondary: baseColors.yellow[5],
  accent: baseColors.gray[7],
  accent2: baseColors.teal[7],
  accent3: baseColors.red[5],
  muted: baseColors.gray[4],
  textMuted: baseColors.gray[6],
  cardBackground: baseColors.white,
  cardBorder: baseColors.orange[3],
  cardHover: baseColors.orange[4],
  heading: baseColors.gray[8],
  shadow: baseColors.orange[3],
  toggleIcon: baseColors.orange[5],
  dotColor: baseColors.orange[3],
  linkColor: baseColors.gray[8],
  divide: baseColors.gray[4],
  linkBorder: baseColors.orange[3],

  modes: {
    dark: {
      text: baseColors.gray[4],
      background: baseColors.gray[8],
      primary: baseColors.blue[5],
      primaryHover: baseColors.blue[6],
      secondary: baseColors.green[5],
      accent: baseColors.blue[7],
      accent2: baseColors.green[5],
      accent3: baseColors.red[2],
      muted: baseColors.gray[6],
      textMuted: baseColors.gray[5],
      cardBackground: baseColors.gray[8],
      cardBorder: baseColors.gray[4],
      cardBorderHover: baseColors.orange[3],
      heading: baseColors.gray[4],
      shadow: baseColors.gray[8],
      toggleIcon: baseColors.gray[4],
      dotColor: baseColors.green[8],
      linkColor: baseColors.gray[1],
      divide: baseColors.gray[7],
      linkBorder: baseColors.green[5],
    },
  },
}
