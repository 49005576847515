// require("fontsource-roboto-slab/700-normal.css")
// require("fontsource-open-sans/400-normal.css")
// require("fontsource-open-sans/700-normal.css")
// require("fontsource-ibm-plex-mono/400-normal.css") // 'IBM Plex Mono', monospace;
// require("fontsource-ibm-plex-mono/700-normal.css") // 'IBM Plex Mono', monospace;
// require("fontsource-ibm-plex-serif/400-normal.css") // 'IBM Plex Serif', serif;
require("fontsource-pacifico/400-normal.css") // 'Pacifico', cursive;
// require("fontsource-kalam/300-normal.css") // 'Kalam', cursive
require("fontsource-kalam/400-normal.css") // 'Kalam', cursive
// require("fontsource-roboto-mono/300-normal.css") // 'Roboto Mono', monospace
require("fontsource-roboto-mono/400-normal.css") // 'Roboto Mono', monospace
