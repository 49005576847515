import { tailwindSpacing, transforms, transitions } from "./tailwind"
import colors from "./colors"

const sharedHeadingStyles = {
  color: `heading`,
  fontFamily: `heading`,
  lineHeight: `heading`,
  fontWeight: `heading`,
}

const fonts = {
  body: `'Roboto Mono', monospace`,
  heading: `'Roboto Mono', monospace`,
  monospace: `'Roboto Mono', monospace`,
  cursive: `'Pacifico', cursive;`,
}

const fontSizes = [
  "0.875rem",
  "1rem",
  "1.25rem",
  "1.5rem",
  "1.875rem",
  "2.25rem",
  "3rem",
  "4rem",
  "4.5rem",
]


const breakpoints = [`600px`, `900px`]

const colorModeTransition =
  "background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)"

const floatTransition = "margin 150ms cubic-bezier(0.4, 0, 0.2, 1)"

export default {
  initialColorModeName: `light`,
  useCustomProperties: `true`,
  colors,
  fonts,
  breakpoints,
  fontSizes,
  tailwindSpacing,
  transforms,
  transitions,
  colorModeTransition,
  floatTransition,

  // https://github.com/system-ui/theme-ui/blob/master/packages/preset-base/src/index.ts
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],

  fontWeights: {
    body: 300,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.625,
    heading: 1.25,
  },

  // variants
  // theme-ui components

  // <Container>

  layout: {
    container: {
      maxWidth: `900px`,
      px: 2,
    },
  },

  sizes: {
    container: `900px`,
  },

  // <Link>

  links: {
    secondary: {
      color: `linkColor`,
      textDecoration: `none`,
      ":hover": {
        color: `accent2`,
        textDecoration: `underline`,
      },
      ":focus": {
        color: `accent2`,
      },
    },
    logo: {
      color: `accent2`,
    },
  },

  // <Image>

  images: {
    flag: {
      width: 48,
      height: 48,
      display: "inline-block",
      verticalAlign: "middle",
    },
  },

  // Own variants

  shadows: {
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
  },

  cards: {
    country: {
      fontFamily: `monospace`,
      fontSize: [2],
      backgroundColor: `cardBackground`,
      px: 2,
      pb: 2,
      border: t => `1px solid ${t.colors.cardBorder}`,
      boxShadow: t => `3px 3px 0px ${t.colors.shadow}`,
      // transition: `all 0.3s ease-in-out`,
      "&:hover, &:focus": {
        border: t => `1px solid ${t.colors.cardHover}`,
        boxShadow: t => `3px 3px 0px ${t.colors.cardHover}`,
      },
    },
    word: {
      fontFamily: `monospace`,
      fontSize: [2],
      // backgroundColor: `cardBackground`,
      p: 3,
      borderBottom: t => `1px dotted ${t.colors.orange[3]}`,
      // border: t => `1px solid ${t.colors.cardBorder}`,
      // boxShadow: t => `3px 3px 0px ${t.colors.shadow}`,
      // // transition: `all 0.3s ease-in-out`,
      // "&:hover, &:focus": {
      //   border: t => `1px solid ${t.colors.cardHover}`,
      //   boxShadow: t => `3px 3px 0px ${t.colors.cardHover}`,
      // },
    },
  },

  dividers: {
    bottom: {
      borderBottomStyle: `solid`,
      borderBottomWidth: `1px`,
      borderBottomColor: `divide`,
      pb: [4, 2],
    },
  },

  // mdx

  styles: {
    root: {
      fontFamily: `body`,
      lineHeight: `body`,
      fontWeight: `body`,

      // https://developer.mozilla.org/de/docs/Web/CSS/text-rendering
      textRendering: `optimizeLegibility`,

      // https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth
      WebkitFontSmoothing: `auto`,
      MozOsxFontSmoothing: `auto`,

      color: `text`,
      backgroundColor: `background`,
    },

    a: {
      color: "linkColor",
      textDecoration: t => `underline solid ${t.colors.text} 2px;`,
      "::before": {
        height: `5px`,
        position: `absolute`,
        backgroundColor: t => `${t.colors.linkBorder}`,
        content: `""`,
        width: `100%`,
        bottom: `-2px`,
        zIndex: `-1`,
        transition: `transform 0.1s ease 0s`,
        transform: `skew(-20deg) rotate(var(--rotate)) scaleX(var(--scaleX))`,
      },
    },

    p: {

      py: 3,
      fontSize: [0, 1],
      lineHeight: `body`,
    },
    ul: {
      listStyleType: "square",
      li: {
        lineHeight: `body`,
      },
    },
    ol: {
      li: {

        lineHeight: `body`,
      },
    },
    h1: {
      ...sharedHeadingStyles,
      fontSize: [2, 4],
      mt: 4,
    },
    h2: {
      ...sharedHeadingStyles,
      fontSize: [2, 3],
      mt: 4,
    },
    h3: {
      ...sharedHeadingStyles,
      fontSize: [2],
      mt: 4,
    },
    h4: {
      ...sharedHeadingStyles,
      fontSize: [1],
      mt: 3,
    },
    h5: {
      ...sharedHeadingStyles,
      fontSize: [1, 2],
      mt: 3,
    },
    h6: {
      ...sharedHeadingStyles,
      fontSize: 1,
      mt: 3,
    },
    hr: {
      bg: 'muted',
      border: 0,
      height: '1px',
      m: 3,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
}
